<template>
  <Dialog
    v-model:visible="showCampaignMultiselect"
    modal
    header="Campaigns"
    :closable="false"
    :style="{ width: '50vw' }"
  >
    <div class="flex flex-wrap pb-5">
      <div class="target-dropdown">
        <label
          for="campaignTargets"
          class="block pb-2 text-base font-semibold"
        >
          Campaigns
        </label>
        <MultiSelect
          id="metricCampaigns"
          v-model="value.campaigns"
          class="full-dropdown"
          :options="campaigns"
          option-label="name"

          placeholder="Select one or more campaigns"
          :filter="true"
          display="chip"
          :disabled="!editable || value.allCampaignsSelected"
          :show-toggle-all="false"
        />
      </div>
    </div>
    <div class="flex">
      <div class="flex justify-content-end w-full">
        <div class="justify-content-end w-full">
          <Checkbox
            v-model="value.allCampaignsSelected"
            input-id="selectAllCampaignsCheckbox"
            :binary="true"
          />
          <label
            for="selectAllCampaignsCheckbox"
            class="ml-2 mr-3"
          > Select all campaigns </label>
        </div>
        <Button
          size="small"
          @click="showCampaignMultiselect = false"
        >
          OK
        </Button>
      </div>
    </div>
  </Dialog>
  <div class="flex flex-wrap mb-3">
    <div class="mr-3">
      <label
        for="metricName"
        class="block pb-2 text-base font-semibold"
      >
        Metric Group Name
      </label>
      <InputText
        id="metricName"
        v-model="value.name"
        :disabled="!editable"
      />
    </div>
    <div
      v-if="isBigCampaignProfile()"
      class="mr-7 "
    >
      <label
        for="metricName"
        class="block pb-2 text-base font-semibold visible-hidden"
      >
        Metric Group Name
      </label>
      <Button
        class="p-button-secondary"
        label="Select campaigns"
        icon="pi pi-external-link"
        :disabled="!editable"
        @click="showCampaignMultiselect = true"
      />
      <span />
    </div>
    <template v-else>
      <div
        class="target-dropdown pb-5"
      >
        <label
          for="campaignTargets"
          class="block pb-2 text-base font-semibold"
        >
          Campaigns
        </label>
        <MultiSelect
          id="metricCampaigns"
          v-model="value.campaigns"
          class="full-dropdown"
          :options="campaigns"
          option-label="name"
          placeholder="Select one or more campaigns"
          :filter="true"
          display="chip"
          :disabled="!editable"
        />
      </div>
    </template>
  </div>
  <div class="flex flex-wrap">
    <MetricData
      v-model="value.metrics"
      :editable="editable"
      :edit-id="value.editId"
      :metric-id="metricId"
    />
  </div>
</template>
<script>
import MetricData from './MetricData.vue';
import {
  isBigProfile,
} from '../../../../../constants/bidRulesConstants';

export default {
  components: { MetricData },
  props: {
    initialValue: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    metricId: {
      type: String,
      required: true,
    },
  },
  emits: ['updateForm'],
  data() {
    return {
      value: JSON.parse(JSON.stringify(this.initialValue)),
      showCampaignMultiselect: false,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.$emit('updateForm', val);
      },
      deep: true,
    },
    initialValue: {
      handler(val) {
        const value = JSON.parse(JSON.stringify(val));

        this.value = value;
      },
      deep: true,
    },
  },
  methods: {
    isBigCampaignProfile() {
      return isBigProfile(this.campaigns.length);
    },

  },
};
</script>

<style scoped>
    .target-dropdown {
      flex: 2;
    }

    .full-dropdown {
      min-width: 25rem;
      max-width: 25rem;
    }

    .visible-hidden {
      visibility: hidden;
    }
</style>

<style>
  .p-multiselect-header .p-checkbox::before {
    content: "Select All ";
    display: inline-block;
    width: 65px;
    height: 15px;
    margin-right: 5px;
    margin-top: 2px;
  }
  .p-multiselect-header .p-checkbox {
    width: 90px !important;
  }
</style>
